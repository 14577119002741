import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CommonSelectionInterface } from "src/app/share/interface/common.interface";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { DateFormValue } from "src/app/share/constants/constants";
import { AlertService } from "src/app/share/service/alert.service";
import {
  ConfirmationDialog,
  ConfirmationDialogModel,
} from "src/app/share/dialog/confirmation-dialog/confirmation.dialog";
import { UploadFileComponent } from "src/app/promo-code-generation/_components/upload-file/upload-file.component";
import { StatusDialogComponent } from "src/app/promo-code-generation/_components/status-dialog/status-dialog.component";
import { triggerFileDownload } from "src/app/share/helpers/downloadFile.helper";
import {
  PromoCodeListingInterface,
  StatusTrackerModel,
} from "src/app/promo-code-generation/_interfaces/promoCode.interface";
import {
  DealerPromoCodeListingRequest,
  PromoCodeDealerService,
} from "./services/promo-code-dealer.service";
import { FormControl } from "@angular/forms";
import { DealerQuotationService } from "src/app/dealer/services/dealer-quotation.service";
import * as moment from "moment";

@Component({
  selector: "app-promo-code-dealer",
  templateUrl: "./dealer.component.html",
  styleUrls: ["./dealer.component.scss"],
})
export class PromoCodeDealerComponent implements OnInit, OnDestroy {
  endDate: Date;
  startDate: Date;
  isLoading: boolean = false;
  length = 0;
  selectedFilter: string;
  creationStartDate: Date;
  creationEndDate: Date;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  status: string[] = [];

  displayedColumns: string[] = [
    "creationDate",
    "promoName",
    "promoCode",
    "dealerCategory",
    "company",
    "individual",
    "effectiveStartDate",
    "effectiveEndDate",
    "quantity",
    "discountPercent",
    "discountAmount",
    "status",
    "remarks",
    "action",
  ];
  filterForm: any = {
    creationDate: null,
    effectiveDate: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
    searchTerm: "",
    status: [],
  };

  selected: PromoCodeListingInterface;
  filterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";
  lastFilterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";

  sortIndex: number = 0;
  sortDirection: "asc" | "desc" = "asc";
  limit: number = 50;
  offset: number = 1;
  list: PromoCodeListingInterface[] = [];
  gmtOffset = "+08:00";

  filterTouched: boolean = false;
  statusOptions: string[] = [];
  selectedStatus: string[] = this.statusOptions;
  filterStatusList: string[] = this.statusOptions;

  dealerList: string[] = [];
  selectedDealer: string[] = this.dealerList;
  filterDealerList: string[] = this.dealerList;

  search: FormControl;
  subscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private promoCodeDealerService: PromoCodeDealerService,
    private alertService: AlertService,
    private bottomSheet: MatBottomSheet,
    private dealerQuotationService: DealerQuotationService,
  ) {
    this.search = new FormControl(null);
    this.subscription = this.search.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnInit(): void {
    this.getAllList();
  }

  async getAllList(): Promise<void> {
    const isStatusCalled = await this.getStatusList();
    const isDealerCalled = await this.getDealerList();

    // [02/10/24] Temporary solution - wait until both list being called only call getLising API
    if (!isStatusCalled && !isDealerCalled) return;

    this.getData();
  }

  private async getStatusList(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.promoCodeDealerService.getStatusList().subscribe({
        next: (x: any) => {
          this.isLoading = false;
          x.forEach((obj) => {
            this.statusOptions.push(obj.name);
          });
          resolve(true);
        },
        error: (_err) => {
          this.isLoading = false;
          this.alertService.openSnackBar("Failed to retrieve status");
          resolve(true);
        },
      });
    });
  }

  async getDealerList(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.dealerQuotationService.getDealerSelection().subscribe({
        next: (x: any) => {
          x.forEach((obj) => {
            this.dealerList.push(obj.name);
          });
          resolve(true);
        },
        error: (_err) => {
          this.isLoading = false;
          this.alertService.openSnackBar("Failed to retrieve dealer category");
          resolve(true);
        },
      });
    });
  }

  private getData(): void {
    this.isLoading = true;
    this.length = 0;

    let request: DealerPromoCodeListingRequest = {
      limit: this.limit,
      offset: this.offset,
      text: this.search?.value || null,
      columnIndex: this.sortIndex,
      sortDirection: this.sortDirection,
      creationStartDate: this.creationStartDate,
      creationEndDate: this.creationEndDate,
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate,
      statusList: this.selectedStatus,
      dealerList: this.selectedDealer,
    };

    this.promoCodeDealerService.getListing(request).subscribe({
      next: (x) => {
        this.isLoading = false;
        this.list = x.data;
        this.length = x.count;
      },
      error: (err) => {
        this.isLoading = false;
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  filter(): void {
    this.offset = 1;
    this.getData();
  }

  startDateClose() {
    if (this.startDate == null) {
      this.filterSelected = this.lastFilterSelected;
      switch (this.filterSelected) {
        case "today":
          this.setTodayDate();
          break;
        case "week":
          this.setCurrentWeekDate();
          break;
        case "month":
          this.setCurrentMonthDate();
          break;
        case "year":
          this.setCurrentYearDate();
          break;
        case "all":
          this.resetDate();
          break;
      }
    }
  }

  setTodayDate(hasFilter: boolean = true) {
    this.startDate = new Date();
    this.endDate = new Date();
    this.setDefaultTimeRange();
    if (hasFilter) {
      this.filter();
    }
  }

  setCurrentWeekDate() {
    this.startDate = moment().startOf("week").toDate();
    this.endDate = moment().endOf("week").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentMonthDate() {
    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentYearDate() {
    this.effectiveStartDate = moment().startOf("year").toDate();
    this.effectiveEndDate = moment().endOf("year").toDate();

    this.setDefaultTimeRange();
    this.filter();
  }

  resetDate() {
    this.startDate = null;
    this.endDate = null;
    this.filter();
  }

  endDateClose() {
    if (this.endDate == null) {
      this.endDate = this.startDate;
    }
    this.filter();
  }

  private setDefaultTimeRange() {
    if (this.creationStartDate && this.creationEndDate) {
      this.creationStartDate.setHours(0, 0, 0, 0);
      this.creationEndDate.setHours(23, 59, 59, 999);
    }
    if (this.effectiveStartDate && this.effectiveEndDate) {
      this.effectiveStartDate.setHours(0, 0, 0, 0);
      this.effectiveEndDate.setHours(23, 59, 59, 999);
    }
  }

  changeDate(type: string, range: DateFormValue) {
    switch (range) {
      case "Year":
        if (type === "CREATION") {
          this.creationEndDate = moment().endOf("year").toDate();
          this.creationStartDate = moment().startOf("year").toDate();
          this.filterForm.creationDate = "Year";
        } else {
          this.effectiveStartDate = moment().startOf("year").toDate();
          this.effectiveEndDate = moment().endOf("year").toDate();

          this.filterForm.effectiveDate = "Year";
        }
        break;
      case "Month":
        if (type === "CREATION") {
          this.creationEndDate = moment().endOf("month").toDate();
          this.creationStartDate = moment().startOf("month").toDate();
          this.filterForm.creationDate = "Month";
        } else {
          this.effectiveEndDate = moment().endOf("month").toDate();
          this.effectiveStartDate = moment().startOf("month").toDate();
          this.filterForm.effectiveDate = "Month";
        }

        break;
      case "Week":
        if (type === "CREATION") {
          this.creationStartDate = moment().startOf("week").toDate();
          this.creationEndDate = moment().endOf("week").toDate();

          this.filterForm.creationDate = "Week";
        } else {
          this.effectiveEndDate = moment().endOf("week").toDate();
          this.effectiveStartDate = moment().startOf("week").toDate();
          this.filterForm.effectiveDate = "Week";
        }

        break;
      case "Today":
        if (type === "CREATION") {
          this.creationStartDate = new Date();
          this.creationEndDate = new Date();
          this.filterForm.creationDate = "Today";
        } else {
          this.effectiveStartDate = new Date();
          this.effectiveEndDate = new Date();
          this.filterForm.effectiveDate = "Today";
        }

        break;
      case "All":
        if (type === "CREATION") {
          this.creationStartDate = null;
          this.creationEndDate = null;
          this.filterForm.creationDate = null;
        } else {
          this.effectiveStartDate = null;
          this.effectiveEndDate = null;
          this.filterForm.effectiveDate = null;
        }
        break;
    }

    this.setDefaultTimeRange();

    this.selectedFilter = range;
    this.filter();
  }

  resetDateFilter(field: string) {
    event.stopImmediatePropagation();
    if (field === "creationDate") {
      this.filterForm.creationDate = "All";
      this.creationEndDate = null;
      this.creationStartDate = null;
      this.changeDate("CREATION", "All");
    } else if (field === "effectiveDate") {
      this.effectiveEndDate = null;
      this.effectiveStartDate = null;
      this.filterForm.effectiveDate = "All";
      this.changeDate("EFFECTIVE", "All");
    }
  }

  onStartDateOpen() {
    this.startDate = null;
    this.endDate = null;
  }

  onStartDateSelected(type: string, val: Date) {
    this.filterForm.date = "Custom";
    if (type === "CREATION") {
      this.creationStartDate = val;
    } else {
      this.effectiveStartDate = val;
    }
  }

  onEndDateSelected(type: string, val: Date) {
    if (type === "CREATION") {
      this.creationEndDate = new Date(val.setHours(23, 59, 59, 999));
    } else {
      this.effectiveEndDate = new Date(val.setHours(23, 59, 59, 999));
    }
  }

  onDateRangePickerClosed(type: string) {
    if (this.creationStartDate && this.creationEndDate) {
      this.getData();
    } else if (this.effectiveStartDate && this.effectiveEndDate) {
      this.getData();
    } else {
      this.filterForm.date = "All";
      this.changeDate(type, "All");
    }
  }

  sortChange(sortState: Sort) {
    this.sortDirection = null;
    this.sortIndex = -1;
    if (sortState.direction) {
      this.sortDirection = sortState.direction;
      this.sortIndex = this.displayedColumns.findIndex(
        (x) => x == sortState.active,
      );
    }
    if (this.list.length == 0) return;

    this.getData();
  }

  pageChange(e: PageEvent) {
    this.offset = e.pageIndex + 1;
    this.limit = e.pageSize;
    this.getData();
  }

  getMultiFilterLabel(key: FilterKeyName) {
    switch (key) {
      case "selectedDealer":
        const isSingleSelected = this[key].length === 1;

        if (isSingleSelected) {
          return this[key];
        } else {
          return this[key].length === this.dealerList.length
            ? "All Dealer Category(s)"
            : `${this[key].length} Dealer Category(s) selected`;
        }

      case "selectedStatus":
        return this.selectedStatus.length === 3
          ? "All Status"
          : this.selectedStatus.join(", ");

      default:
        return null;
    }
  }

  onSelectedOptionsChange(): void {
    this.getData();
  }

  onSelectSearchChange(searchValue: string = null, key: FilterKeyName): void {
    const typeList =
      key === "selectedDealer" ? this.dealerList : this.statusOptions;
    const filterTypeList =
      key === "selectedDealer" ? "filterDealerList" : "filterStatusList";

    if (searchValue) {
      let txt = searchValue.toLowerCase().trim();
      this[filterTypeList] = typeList.filter((x) =>
        x.toLowerCase().includes(txt),
      );
    } else {
      this[filterTypeList] = Object.assign([], typeList);
    }
  }

  downloadTemplate(fileName: string) {
    this.promoCodeDealerService.downloadTemplate().subscribe(
      (blob) => {
        triggerFileDownload(blob, `${fileName}.xlsx`);
      },
      (err) => {
        this.alertService.openSnackBar(`Error: ${err.message}`);
      },
    );
  }

  onExport(fileName: string): void {
    this.isLoading = true;

    let request: DealerPromoCodeListingRequest = {
      limit: this.limit,
      offset: this.offset,
      text: this.search?.value || null,
      columnIndex: this.sortIndex,
      sortDirection: this.sortDirection,
      creationStartDate: this.creationStartDate,
      creationEndDate: this.creationEndDate,
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate,
      statusList: this.selectedStatus,
      dealerList: this.selectedDealer,
    };

    this.promoCodeDealerService.getListingExcel(request).subscribe(
      (blob) => {
        triggerFileDownload(blob, `${fileName}.xlsx`);
      },
      (err) => {
        this.alertService.openSnackBar(`Error: ${err.message}`);
      },
    );
  }

  deactivateUser() {
    let data: ConfirmationDialogModel = {
      title: "Deactivate User",
      content: "Are you sure want to deactivate",
      btnColor: "warn",
      okBtnText: "Deactivate",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.promoCodeDealerService
          .deactivate(this.selected.id)
          .subscribe((x) => {
            this.alertService.openSnackBar(
              `Promo Code ${this.selected.code} is deactivated`,
            );
            this.getData();
          });
      }
    });
  }

  openStatus() {
    let data: StatusTrackerModel = {
      title: "Status Tracker",
      type: "dealer",
    };
    let dialogRef = this.dialog.open(StatusDialogComponent, { data: data });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
      }
    });
  }

  uploadFile() {
    const bottomRef = this.bottomSheet.open(UploadFileComponent, {
      backdropClass: "sidenav-container",
      data: {
        type: "dealer",
      },
      disableClose: true,
    });
    bottomRef.afterDismissed().subscribe((result) => {
      if (result && result.success) {
        this.getData();
      }
    });
  }

  isSelectedAll(key: FilterKeyName): boolean {
    if (key === "selectedDealer") {
      return this.selectedDealer.length == this.dealerList.length;
    } else {
      return this.selectedStatus.length == this.statusOptions.length;
    }
  }

  isSelectedOptionsIndeterminate(key: FilterKeyName): boolean {
    const selectedList =
      key === "selectedDealer" ? this.dealerList : this.statusOptions;
    const isAllSelected = selectedList.length === this[key].length;

    if (isAllSelected) return false;

    return this[key].length > 0;
  }

  toggleSelectAll(val: boolean, key: FilterKeyName): void {
    if (val) {
      key === "selectedDealer"
        ? (this.selectedDealer = this.dealerList)
        : (this.selectedStatus = this.statusOptions);
    } else {
      key === "selectedDealer"
        ? (this.selectedDealer = [])
        : (this.selectedStatus = []);
    }

    this.filter();
  }

  resetTextFilter(): void {
    this.search.reset();
  }

  resetFilteredOption(event: any, key: FilterKeyName): void {
    event.stopPropagation();

    if (key === "selectedDealer") {
      this.selectedDealer = this.dealerList;
    } else {
      this.selectedStatus = this.statusOptions;
    }

    this.filter();
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
export type FilterKeyName = "selectedStatus" | "selectedDealer";
